import { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

interface LabeledDivProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  children: ReactNode;
  size?: "small" | "medium" | "large";
}


export const LabeledDiv = ({
  id,
  label,
  children,
  className,
  size = "medium",
  ...rest
}: LabeledDivProps) => (
  <div
    className={classNames(className, "leading-7")}
    {...rest}
    id={id ? `${id}-label` : undefined}
  >
    <div className={
      classNames(
        "font-normal",
        {
          "text-sm": size === "small",
          "text-base": size === "medium",
          "text-lg": size === "large",
        }
      )} id={id ? `${id}-content` : undefined}>
      {label}
    </div>
    {children}
  </div>
);
