import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';

// Define the context type
export interface SecuritySelectionContextType {
  selectedSecurities: string[];
  securityIsSelected: (securityCode: string) => boolean;
  addSecurityToSavingsPlan: (securityCode: string) => Promise<void>;
  addSecuritiesToSavingsPlan: (securityCodes: string[]) => Promise<void>;
  removeSecurityFromSavingsPlan: (securityCode: string) => void;
  clearSelectedSecurities: () => Promise<void>;
}

// Create context with undefined as default value and explicit type
export const SecuritySelectionContext = createContext<SecuritySelectionContextType | undefined>(undefined);

// Provider props type
interface SecuritySelectionProviderProps {
  children: ReactNode;
}

export const SecuritySelectionProvider = ({ children }: SecuritySelectionProviderProps) => {
  const [selectedSecurities, setSelectedSecurities] = useState<string[]>(() => {
    const saved = localStorage.getItem('selectedSecurities');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem('selectedSecurities', JSON.stringify(selectedSecurities));
  }, [selectedSecurities]);

  const addSecurityToSavingsPlan = (securityCode: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      setSelectedSecurities((prev) => {
        if (!prev.includes(securityCode)) {
          const updated = [...prev, securityCode];
          resolve();
          return updated;
        }
        resolve();
        return prev;
      });
    });
  };

  const addSecuritiesToSavingsPlan = (securityCodes: string[]): Promise<void> => {
    return new Promise<void>((resolve) => {
      setSelectedSecurities((prev) => {
        const updated = [...prev, ...securityCodes.filter(code => !prev.includes(code))];
        resolve();
        return updated;
      });
    });
  };

  const removeSecurityFromSavingsPlan = (securityCode: string): void => {
    setSelectedSecurities((prev) => {
      return prev.filter(code => code !== securityCode);
    });
  };

  const securityIsSelected = (securityCode: string): boolean =>
    selectedSecurities.includes(securityCode);

  const clearSelectedSecurities = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setSelectedSecurities(() => {
        resolve();
        return [];
      });
    });
  };

  const value: SecuritySelectionContextType = {
    selectedSecurities,
    securityIsSelected,
    addSecurityToSavingsPlan,
    addSecuritiesToSavingsPlan,
    removeSecurityFromSavingsPlan,
    clearSelectedSecurities
  };

  return (
    <SecuritySelectionContext.Provider value={value}>
      {children}
    </SecuritySelectionContext.Provider>
  );
};