import {ThemeProvider} from "@mui/material";
import {ErrorBoundary} from "components/ErrorBoundary/ErrorBoundary";
import {ConfigProvider} from "providers/ConfigProvider";
import {InitialLanguageProvider} from "providers/InitialLanguageProvider";
import {KeycloakProvider} from "providers/KeycloakProvider";
import {ServiceWorkerRegistrationProvider} from "providers/ServiceWorkerRegistrationProvider";
import "react-toastify/dist/ReactToastify.css";
import "styles/fonts.css";
import muitheme from "./muiTheme";
import {UserWithLinkedContactRoutes} from "./pages/userWithLinkedContact/routes";
import {DetailProvider} from "./providers/ContractIdProvider";
import {PersistedApolloProvider} from "./providers/PersistedApolloProvider";
import {SecuritySelectionProvider} from "./providers/SecuritySelectionProvider";

function App() {
  return (
    <InitialLanguageProvider>
      <ServiceWorkerRegistrationProvider>
        <ErrorBoundary>
          <ConfigProvider>
            <KeycloakProvider>
              <PersistedApolloProvider>
                <DetailProvider>
                  <ThemeProvider theme={muitheme}>
                    <SecuritySelectionProvider>
                      <UserWithLinkedContactRoutes/>
                    </SecuritySelectionProvider>
                  </ThemeProvider>
                </DetailProvider>
              </PersistedApolloProvider>
            </KeycloakProvider>
          </ConfigProvider>
        </ErrorBoundary>
      </ServiceWorkerRegistrationProvider>
    </InitialLanguageProvider>
  );
}

export default App;
