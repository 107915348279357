import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";

interface TableProps {
  children?: ReactNode;
  margin?: boolean;
}
export const Table = ({ children, margin }: TableProps) => {
  return <table className={classNames("w-full", { "mb-3": margin })}>{children}</table>;
};

interface ThProps extends HTMLProps<HTMLTableCellElement> {
  children?: ReactNode;
  left?: boolean;
  hideOnBreakPoint?: string;
}
export const Th = ({ children, left, hideOnBreakPoint, ...restProps }: ThProps) => {
  const hide = useMatchesBreakpoint(hideOnBreakPoint ?? "");
  if (hideOnBreakPoint && !hide) return null;
  return (
    <th
      {...restProps}
      className={classNames(
        "px-2 last:pr-4 first:pl-4 py-3 font-medium",
        left ? "text-left" : "text-right",
        restProps.className
      )}>
      {children}
    </th>
  );
};

interface TdProps extends HTMLProps<HTMLTableCellElement> {
  colSpan?: number;
  children?: ReactNode;
  left?: boolean;
  fit?: boolean;
}
export const Td = ({ children, left, fit, colSpan, ...restProps }: TdProps) => {
  return (
    <td
      {...restProps}
      colSpan={colSpan}
      className={classNames(
        "p-2 last:pr-4 first:pl-4",
        { "w-0": fit },
        left ? "text-left" : "text-right",
        restProps.className
      )}>
      {children}
    </td>
  );
};

interface TrProps extends HTMLProps<HTMLTableRowElement> {
  children?: ReactNode;
  alternate?: boolean;
  onClick?: () => void;
}
export const Tr = ({ children, alternate, onClick, ...restProps }: TrProps) => {
  return (
    <tr
      onClick={(e) => {
        onClick && onClick();
      }}
      {...restProps}
      className={classNames("group", restProps.className, {
        "odd:bg-[#f7f7f7] even:bg-transparent": alternate,
        "hover:bg-green-400/10 cursor-pointer": onClick,
      })}>
      {children}
    </tr>
  );
};
